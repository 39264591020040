<template>
  <q-btn
    v-if="environments"
    class="btn-switch align-right row justify-end"
    :label="`Environment: ${currentEnvironment}`"
    flat
    no-caps
    :disable="loading"
  >
    <q-menu
      fit
      transition-show="jump-down"
      transition-hide="jump-up"
    >
      <q-list separator>
        <q-item
          v-for="(token, environment) in environments"
          :key="environment"
          class="text-black text-capitalize"
          clickable
          @click="changeEnv(token, environment)"
        >
          <q-item-section>
            {{ environment }}
            <q-tooltip>{{ token }}</q-tooltip>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>
<script>
export default {
  props: ['qa'],
  computed: {
    environments () {
      return process.env.VUE_APP_SWITCH_URLS ? JSON.parse(process.env.VUE_APP_SWITCH_URLS) : null
    },
    currentEnvironment () {
      return this.$store.getters['partner/label']
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  methods: {
    changeEnv (token, environment) {
      this.$emit('switch', environment)
      this.$store.dispatch('entities/deleteAll')
      if (this.qa) {
        this.$store.dispatch('partner/switchQAEnvironment', { environment })
      } else {
        this.$store.dispatch('partner/switchEnvironment', { token, environment })
      }
      this.$q.notify({
        message: `Switched to ${environment} environment`,
        color: 'positive',
        icon: 'done_all'
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.btn-switch
  margin-right: 16px
</style>
